import { MuzzoLogoImage } from '@core/components/SVG/MuzzoLogoImage.component';
import { FunctionComponent } from 'react';

export const MuzzoLogoLoader: FunctionComponent = () => {
  return (
    <div className="flex flex-col items-center gap-y-2">
      <MuzzoLogoImage />
      <div className="text-catalina-blue text-sm font-medium ">
        Merci de patienter...
      </div>
    </div>
  );
};
