import { MuzzoLogoLoader } from '@core/components/MuzzoLogoLoader/MuzzoLogoLoader.component';
import { Page } from '@core/components/Page/Page.component';
import { useAuthenticationServiceContext } from 'modules/Authentication/context/AuthenticationServiceContext';

import { useEffect } from 'react';

export default function RootPage() {
  const { authenticationService } = useAuthenticationServiceContext();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    authenticationService.goToLoginPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // No dependencies! We want to run this only once

  return (
    <Page title="Chargement...">
      <div className="flex min-h-screen items-center justify-center">
        <MuzzoLogoLoader />
      </div>
    </Page>
  );
}
